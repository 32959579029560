body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background: #1D1E20;
  color: #DADADB;
  /* max-width: 800px; */
  margin: 0 auto;
}

@media screen and (max-width: 800px) {
  body {
    max-width: 100%;
    padding: 0 20px;
  }
}

.header-section {
  margin: 30px 0;
  text-align: center;
}

.header-section h1 {
  font-size: 2.7rem;
  font-weight: 700;
}

.header-section p {
  font-size: 1rem;
  font-weight: 300;
  margin-top: 10px;
}

.form-section {
  margin: 30px 0;
}

textarea,
button {
  width: 100%;
  border-radius: 5px;
  border: none;
}

.form-control {
  margin-bottom: 20px;
  padding: 20px;
  font-size: 1rem;
  font-family: 'Poppins',
    sans-serif;
  outline: none;
  background-color: #343536;
  color: #DADADB;
  transition: all 0.5s ease-in-out;
}

.loader {
  width: 24px;
  height: 24px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}